import React from "react";
import SEO from "../../components/SEO";
import { useResponsiveValue } from "../../hooks/breakpoints";
import { Container } from "../../components/core";
import { Box } from "theme-ui";
import Page from "../../components/Page";
import { graphql } from "gatsby";

const Adhesion = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;
  const heightResponsive = useResponsiveValue([
    "2600px",
    "2000px",
    "1450px",
    "1450px",
  ]);

  return (
    <Page
      sx={{
        backgroundColor: "#f2f2f2",
      }}
    >
      <SEO title="Adhésion" description={frontmatter.adhesion.text} />

      <Container
        variant="containerLg"
        as="section"
        sx={{
          paddingTop: [8, null, 8],
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: heightResponsive,
          }}
        >
          <iframe
            // onLoad={onLoad}
            id="myFrame"
            src="https://www.helloasso.com/associations/clean-my-calanques/adhesions/campagne-d-adhesion-2022-cmc/widget"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{
              width: "100%",
              // height: "100%",
              overflow: "auto",
              top: 0,
            }}
          />
        </Box>
      </Container>
    </Page>
  );
};

export const query = graphql`
  query AdhesionQueryData {
    markdownRemark(fields: { slug: { eq: "/nous-aider/" } }) {
      frontmatter {
        adhesion {
          text
        }
      }
    }
  }
`;

export default Adhesion;
